$(document).ready(function() {
  $('.map-area').mouseover(function(e) {
    if ($(this).hasClass('no-hover')) {
      return false;
    }
    var areaId = $(e.target).attr('id');
    $('#swissMap').attr('src', 'src/dist/images/' + areaId + '.png');
  });
  $('.map-area').mouseout(function() {
    if ($(this).hasClass('no-hover')) {
      return false;
    }
    $('#swissMap').attr('src', 'src/dist/images/schweiz_default.png');
  });

  $('#swissMap').mappable();
});
