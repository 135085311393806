$(document).ready(function() {
  $('.show-application-form').click(function() {
    $('.application-form').toggleClass('application-form--open');

    if ($('.application-form').hasClass('application-form--open')) {
      $('.show-application-form').text('Formular schliessen');
    } else {
      $('.show-application-form').text('Anmelden');
    }
  });
});
