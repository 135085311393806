$(document).ready(function() {

  var breakpoint = 1200;

  // Toggle sub navigation on mobile
  $('.js-toggle-sub-nav').on('click', function(e) {
    if(window.innerWidth < breakpoint) {
      e.preventDefault();
    }
    $(this).parent().find('.dropdown-nav').toggleClass('is-open');
  });

});
