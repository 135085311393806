$(document).ready(function() {

  // Toggle email fields in account information
  $('.js-toggle-email-fields').click(function() {
    $('.js-email-fields').toggleClass('hidden');
  });

  // Toggle password fields in account information
  $('.js-toggle-password-fields').click(function() {
    $('.js-password-fields').toggleClass('hidden');
  });

});
