$(document).ready(function() {

  // Open mobile category menu
  $('.js-open-mobile-category-nav').bind('click', function() {
    $('.category-nav').addClass('is-open');
    $('.category-menu-overlay').removeClass('fade');
  });

  // Close mobile category menu
  $('.category-menu-close, .category-menu-overlay').click(function() {
    $('.category-nav').removeClass('is-open');
    $('.category-menu-overlay').addClass('fade');
  });
});
